.chessboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.status-bar {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 0vh;
    background-color: #333;
    border-radius: 4vh;
    margin-bottom: 1vh;
    font-size: 0.5rem;
}

.action-buttons {
    display: flex;
    gap: 2vw;
    margin-bottom: 1vh;
}

.action-button {
    background-color: #0056b3;
    border: none;
    color: white;
    padding: 1.2vh;
    text-align: center;
    font-size: 1rem;
    border-radius: 1vh;
    cursor: pointer;
    transition: 0.3s ease;
}

.action-button:hover {
    background-color: #5C7F6A;
}

#chessboard {
    display: grid;
    grid-template-columns: repeat(8, var(--tileSize));
    grid-template-rows: repeat(8, var(--tileSize));
    width: fit-content;
    background-color: black;
    border: 0.2vh solid white;
    border-radius: 0.5vh;
}

