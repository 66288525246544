#app {
  display: grid;
  place-content: center;
  height: 100vh;
  background-color: black;
}

.title {
  text-align: center;
  font-size: 2.0em;
  margin-bottom: 1vh;
  color: white;
}